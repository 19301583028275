import { acceptHMRUpdate, defineStore } from "pinia";
import { numberWithSpaces } from "~/libs/ui";
import { useDocs } from "~/store/docs";
import { useApi } from "~/store/app/api";

import qs from "qs";

export const usePrograms = defineStore("programs", {
    state: () => {
        const config = useRuntimeConfig();

        return {
            apiBase: config.public.apiBase,
            cache: config.public.cache,
            programsList: [],
            customProgramsList: [],
            order: false,
            age: "",
            type: "",
            home: false,
            program: "",
            programCost: "",
            programCostDefault: "",
            programDocs: [],
            leadDoc: null
        };
    },

    getters: {
        createApiUrl: (state) => (url) => {
            return `${state.apiBase}${url}`;
            // return `${state.apiBase}${url}${
            //     state.cache ? "" : "&" + new Date().getTime()
            // }`;
        },
        cartDetails(state) {
            return state.customProgramsList
                .flatMap((item) => {
                    return item.attributes.Combo.filter((combo) =>
                        combo.options.data.some((option) => option.attributes.checked)
                    );
                })
                .map((item, idx) => ({
                    item: item,
                    id: item.id,
                    title: item.title,
                    __component: item.__component,
                    options: Object.values(item.options.data).filter(
                        (option) => option.attributes.checked
                    )
                }));
        },
        cartIsEmpty(state) {
            return !state.cartDetails.length;
        },
        totalSum(state) {
            return Object.values(state.cartDetails)
                .reduce((acc, item) => {
                    const multi =
                        item.options
                            .filter((option) => option.attributes.checked)
                            .reduce((acc, service) => acc + service?.attributes.price, 0) ||
                        0;
                    return acc + Number(multi);
                }, 0);
        },
        totalCount(state) {
            return Object.values(state.cartDetails).reduce((acc, item) => {
                const multi = item.options.filter(
                    (option) => option.attributes.checked
                ).length;
                return acc + Number(multi);
            }, 0);
        },
        programsByAges(state) {
            const programs = state.programsList.reduce((acc, program, idx) => {
                const age = program?.attributes?.ages?.data[0]?.attributes?.title;
                if (!Array.isArray(acc[age])) {
                    acc[age] = [];
                }
                acc[age].push(program);
                return acc;
            }, {});
            return programs;
        },
        customProgramsByAges(state) {
            const programs = state.customProgramsList.reduce((acc, program, idx) => {
                const age = program?.attributes?.ages?.data[0]?.attributes?.title;
                if (!Array.isArray(acc[age])) {
                    acc[age] = [];
                }
                acc[age].push(program);
                return acc;
            }, {});
            return programs;
        },
        listAges(state) {
            return Object.keys(state.programsByAges).map((item, idx) => ({
                id: idx,
                title: item
            }));
        },
        listCustomProgramAges(state) {
            function compare(a, b) {
                if (a.title < b.title) {
                    return -1;
                }
                if (a.title > b.title) {
                    return 1;
                }
                return 0;
            }

            return Object.keys(state.customProgramsByAges).map((item, idx) => ({
                id: idx,
                title: item
            })).sort(compare);
        },
        checkIncludeChecked: (state) => (list) => {
            return list.options.data.find((option) => option.attributes.checked);
        },
        checkAgeIncludeChecked: (state) => (list) => {
            for (let combo in list) {
                if (
                    list[combo].options.data.find((option) => option.attributes.checked)
                ) {
                    return true;
                }
            }
        }
    },

    actions: {
        async fetchPrograms() {
            const apiStore = useApi();

            let populate = {
                docs: {
                    populate: {
                        photo_mini: "*",
                        clinics: "*"
                    }
                },
                docs_home: {
                    populate: {
                        photo_mini: "*",
                        clinics: "*"
                    }
                },
                Combo: {
                    populate: {
                        Options: {
                            populate: "*"
                        }
                    }
                },
                price: {
                    populate: {
                        clinic: "*"
                    }
                },
                price_home: {
                    populate: {
                        clinic: "*"
                    }
                },
                price_list: {
                    fields: ["price"],
                    populate: {
                        clinic: "*",
                        doc_list: {
                            fields: ["price"],
                            populate: {
                                doc: {
                                    populate: {
                                        photo_mini: "*",
                                        clinics: "*"
                                    }
                                }
                            }
                        }
                    }
                },
                price_list_home: {
                    fields: ["price"],
                    populate: {
                        clinic: "*",
                        doc_list: {
                            fields: ["price"],
                            populate: {
                                doc: {
                                    populate: {
                                        photo_mini: "*",
                                        clinics: "*"
                                    }
                                }
                            }
                        }
                    }
                },
                ages: "*"
            };

            const cache = "&cache=get";
            const query = qs.stringify({
                populate,
                pagination: { pageSize: 1000 },
                sort: "id"
            }, { encode: true });
            const url = `/api/programs?${query}${cache}`;
            console.log(url);
            this.programsList = (await apiStore.fetchData(url));
        },
        async fetchCustomPrograms(clinicId) {
            const customProgramsList = await fetch(
                this.createApiUrl(`/api/cprograms?populate[Combo][populate][options][populate][price][populate][clinic]=*&populate[Combo][populate][options][populate][price][populate][docs]=*&populate[ages]=*&populate[docs][populate]=*`)
            )
                .then((response) => response.json())
                .then((result) => result.data)
                .catch((error) => console.log("error", error));

            customProgramsList.forEach((item) => {
                item.attributes?.Combo.forEach((combo) => {
                    for (let i = 0; i < combo.options?.data?.length; i++) {
                        const el = combo.options?.data[i];
                        el.attributes["checked"] = false;
                        el.attributes["ageId"] = item.attributes.ages.data[0].id;
                        el.attributes.docs = el.attributes.price.find(
                            (res) => res.clinic?.data?.attributes?.clinicId === clinicId
                        )?.docs?.data;
                        el.attributes.price = el.attributes.price.find(
                            (res) => res.clinic?.data?.attributes?.clinicId === clinicId
                        )?.Price;
                        if (!el?.attributes?.price) {
                            el.attributes.price = 0;
                        }

                    }
                });
            });
            this.customProgramsList = customProgramsList;
        },
        setServiceChecked(programKey, categoryId, serviceId, component) {
            if (
                component === "pages.one" &&
                !this.customProgramsList[programKey].attributes.Combo[categoryId]
                    .options.data[serviceId].attributes.checked
            ) {
                for (let service of this.customProgramsList[programKey].attributes
                    .Combo[categoryId].options.data) {
                    service.attributes.checked = false;
                }
            }

            this.customProgramsList[programKey].attributes.Combo[
                categoryId
                ].options.data[serviceId].attributes.checked =
                !this.customProgramsList[programKey].attributes.Combo[categoryId]
                    .options.data[serviceId].attributes.checked;
        },
        createBasketInfo(type) {
            const docsStore = useDocs();
            const doc = docsStore.getDocById(this.leadDoc);
            let doctor = "Любой врач";
            if (this.leadDoc) {
                doctor = `${doc.attributes.lname} ${doc.attributes.fname}`;
            }

            if (type === "custom") {
                let program = "";
                for (let category of this.cartDetails) {
                    program += "<b>" + category.title + ":</b>" + "<br/>";
                    for (let option of category.options) {
                        program +=
                            option.attributes.title +
                            ` -${numberWithSpaces(option.attributes.price)} р` +
                            "<br/>";
                    }
                    program += "<br/>";
                    program += "<br/>";
                }
                return {
                    cost: numberWithSpaces(this.totalSum) + " р",
                    age: this.age,
                    program,
                    doctor
                };
            } else {
                return {
                    cost: numberWithSpaces(this.programCost) + " р",
                    age: this.age,
                    program: this.home ? this.program + " дома" : this.program,
                    doctor
                };
            }
        }
    }
});

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(usePrograms, import.meta.hot));
}
